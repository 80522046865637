<template>
  <div>
    <Pane />
    <a-card class="container">
      <MonthTable
        v-if="detail.type === 'projectPlanMonth'"
        :year="year"
        :month="month"
        :date="date"
        :name="detail.fillingUserName"
        :loading="loading"
        :list="list"
        :disabled="false"
        @change="payload => list = payload"
      />
      <YearTable
        v-if="detail.type === 'projectPlanYear'"
        :year="year"
        :name="detail.fillingUserName"
        :loading="loading"
        :list="list"
        :disabled="false"
        @change="payload => list = payload"
      />

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button
            type="primary"
            :disabled="detail.confirmStatus !== 'project_plan_confirm_status_to_be_confirmed'"
            :loading="saveLoading"
            @click="onClick('confirm')"
          >确认</a-button>
          <a-button
            :disabled="detail.confirmStatus !== 'project_plan_confirm_status_to_be_confirmed'"
            :loading="saveLoading"
            @click="onClick('goBack')"
          >退回</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import MonthTable from "./components/month-table.vue";
import YearTable from "./components/year-table.vue";
import request from "@/api/request";

function fetchDetail(data) {
  return request({
    url: "/project-service/projectPlan/detail/" + data.id,
    method: "post"
  });
}

function change(data) {
  return request({
    url: "/project-service/projectPlan/plan/status",
    method: "post",
    data
  });
}

export default {
  components: {
    MonthTable,
    YearTable
  },

  data() {
    return {
      year: 0,
      month: 0,
      date: 0,
      loading: false,
      detail: {},
      list: [],
      saveLoading: false
    };
  },

  mounted() {
    const { id } = this.$route.query;
    if (!id) return;
    this.loading = true;
    fetchDetail({
      id
    })
      .then(res => {
        this.detail = res || {};

        this.list = Array.isArray(this.detail.subList)
          ? this.detail.subList.map(item => {
              return {
                ...item,
                key: item.id,

                productionUnitName2: item.contract
                  ? item.contract.productionUnitName2
                  : "",
                productionMasterName: item.contract
                  ? item.contract.productionMasterName
                  : "",

                disabled:
                  res.type === "projectPlanYear"
                    ? false
                    : item.hisList.length === 1
                    ? false
                    : true // 如果只有一个日期，说明是最新增加了项目，可以删，否则都是历史项目，不能删
              };
            })
          : [];

        if (this.detail.type === "projectPlanYear") {
          this.year = this.detail.year;
        } else {
          if (
            Array.isArray(this.detail.subList) &&
            this.detail.subList.length > 0
          ) {
            const hisList = this.detail.subList[0].hisList || [];
            const last = hisList[hisList.length - 1];
            const obj = new Date(last.date);
            this.year = obj.getFullYear();
            this.month = obj.getMonth();
            this.date = obj.getDate();
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    onClick(type) {
      if (this.list.length === 0) {
        this.$message.error("请选择项目");
        return;
      }

      let errorFlag = false;

      check: for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        let progress = item.progress || 0;

        for (let j = 0; j < item.hisList.length; j++) {
          const proportion = item.hisList[j].proportion;
          if (typeof proportion !== "number") {
            this.$message.error("进度请输入数字");
            errorFlag = true;
            break check;
          } else {
            const sum = proportion + progress;
            if (sum > 100) {
              this.$message.error(
                item.projectName + "计划进度加累计项目进度不得超过100%"
              );
              errorFlag = true;
              break check;
            }
          }
        }
      }

      if (errorFlag) {
        return;
      }

      const that = this;
      this.$confirm({
        title: "是否继续？",
        onOk() {
          if (type === "confirm") {
            that.confirm();
          } else if (type === "goBack") {
            that.goBack();
          }
        }
      });
    },

    confirm() {
      this.saveLoading = true;
      change({
        id: this.detail.id,
        confirmStatus: "project_plan_confirm_status_confirm",
        planSubList: this.list
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    goBack() {
      this.saveLoading = true;
      change({
        id: this.detail.id,
        confirmStatus: "project_plan_confirm_status_return",
        planSubList: this.list
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>



<style lang="less" scoped>
.center {
  padding: 80px 0;
}
</style>
